@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply py-2 px-4 rounded;
  }

  .btn-blue {
    @apply bg-blue-500 text-white;
  }

  .btn-blue:hover {
    @apply bg-blue-700;
  }

  .card {
    @apply rounded overflow-hidden shadow-lg;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhaWx3aW5kLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxjQUFjO0FBQ2Qsb0JBQW9CO0FBQ3BCLG1CQUFtQjs7QUFFbkI7RUFDRTtJQUNFLHdCQUF3QjtFQUMxQjs7RUFFQTtJQUNFLDZCQUE2QjtFQUMvQjs7RUFFQTtJQUNFLGtCQUFrQjtFQUNwQjs7RUFFQTtJQUNFLHdDQUF3QztFQUMxQztBQUNGIiwiZmlsZSI6Im1haW4uY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHRhaWx3aW5kIGJhc2U7XHJcbkB0YWlsd2luZCBjb21wb25lbnRzO1xyXG5AdGFpbHdpbmQgdXRpbGl0aWVzO1xyXG5cclxuQGxheWVyIGNvbXBvbmVudHMge1xyXG4gIC5idG4ge1xyXG4gICAgQGFwcGx5IHB5LTIgcHgtNCByb3VuZGVkO1xyXG4gIH1cclxuXHJcbiAgLmJ0bi1ibHVlIHtcclxuICAgIEBhcHBseSBiZy1ibHVlLTUwMCB0ZXh0LXdoaXRlO1xyXG4gIH1cclxuXHJcbiAgLmJ0bi1ibHVlOmhvdmVyIHtcclxuICAgIEBhcHBseSBiZy1ibHVlLTcwMDtcclxuICB9XHJcblxyXG4gIC5jYXJkIHtcclxuICAgIEBhcHBseSByb3VuZGVkIG92ZXJmbG93LWhpZGRlbiBzaGFkb3ctbGc7XHJcbiAgfVxyXG59XHJcbiJdfQ== */


/* MODULES */
/* Refactor to X css arch */
/* ICONS */
.files__delete-file {
  color: #ED1E79;
  cursor: pointer;
  font-size: 2.5rem !important;
}