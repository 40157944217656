@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply py-2 px-4 rounded;
  }

  .btn-blue {
    @apply bg-blue-500 text-white;
  }

  .btn-blue:hover {
    @apply bg-blue-700;
  }

  .card {
    @apply rounded overflow-hidden shadow-lg;
  }
}
